




















































import { MovimentacaoDeCaixa, TurnoDeVenda } from '@/models'
import Vue from 'vue'
import { Component, Ref, Prop } from 'vue-property-decorator'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { MovimentarCaixaUseCase } from '@/usecases'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { nextTick } from '@/shareds/utils'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import moment from 'moment'
import { formatarMoeda } from '@/shareds/formatadores'
import { maiorQueZero, obrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		Confirmacao,
		CampoDecimal,
	},
})
export default class DialogoDeSangria extends Vue {
	@Ref() confirmacaoDeMovimentacao!: Confirmacao
	@Ref() campoDeValor!: CampoDecimal
	@Prop({ type: String }) lojaId!: string

	mostra = false
	gerandoMovimentacao = false
	nome: string | null = null
	pdv: TurnoDeVenda | null = null
	valorEmCaixa: number | null = null
	turnoId: string | null = null
	movimentacaoObject: MovimentacaoDeCaixa | null = null
	valorDaSangria: number | null = null
	movimentarCaixaUseCase = new MovimentarCaixaUseCase()

	obrigatorio = obrigatorio
	maiorQueZero = maiorQueZero

	mostrar(nome: string, valorEmCaixa: number, turnoDeVendaId: string) {
		this.mostra = true
		this.nome = nome
		this.valorEmCaixa = valorEmCaixa
		this.turnoId = turnoDeVendaId
	}

	async adicionarMovimentacao() {
		if (!(await this.confirmacaoDeMovimentacao.mostrar())) {
			this.focarPrimeiroCampo()
			return
		}

		if (!this.valorDaSangria)
			throw new Error('Não informado valor para movimentação')

		this.gerandoMovimentacao = true

		this.movimentacaoObject = {
			id: '',
			tipoMovimentacao: 'Sangria',
			valor: this.valorDaSangria,
			dataHoraMovimentacao: moment().toDate(),
			observacao: '',
			anexos: [],
			operador: null,
			motivo: {
				id: 'c4c0e524-2af3-4b53-8499-96fba3a41d54',
				nome: 'DEPOSITO',
				utilizadoEm: ['Sangria'],
				despesa: false,
				temObservacao: false,
				lojaId: '',
			},
		}

		try {
			if (!this.turnoId) return

			const movimentacaoAtualizada = await this.movimentarCaixaUseCase.execute(
				this.turnoId,
				this.movimentacaoObject,
			)

			VendaModule.addMovimentacao(movimentacaoAtualizada)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.gerandoMovimentacao = false
			this.valorDaSangria = null
			this.mostra = false
		}
	}

	get tituloDeConfirmacaoDaMovimentacao() {
		return `Confirma Sangria de R$ ${formatarMoeda(this.valorDaSangria || 0)}`
	}

	async focarPrimeiroCampo() {
		await nextTick()
		this.campoDeValor.focus()
	}
}
