















































































































































import { Component, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import DialogoDeSangria from '@/views/application/fechamentosdecaixa/DialogoDeSangria.vue'
import DialogoValorPagamentos from '@/views/application/fechamentosdecaixa/DialogoValorPagamentos.vue'
import { PdvsComValores, PontoDeVenda, Venda } from '@/models'
import { Inject } from 'inversify-props'
import type { Loja } from '@/models'
import type { LojaServiceAdapter } from '@/usecases'
import Axios, { CancelTokenSource } from 'axios'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import {
	obterTotalDaVenda,
	obterTotalDosItensDaVenda,
} from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		DialogoDeSangria,
		DialogoValorPagamentos,
	},
})
export default class TelaDeSangria extends Vue {
	@Ref() dialogoDeSangria!: DialogoDeSangria
	@Ref() dialogoValorPagamentos!: DialogoValorPagamentos
	@PropSync('loja', { type: Object }) lojaSync?: Loja

	obterTotalDaVenda = obterTotalDaVenda
	formatarMoeda = formatarMoeda

	carregando = false
	pontosDeVenda: PontoDeVenda | null = null
	buscandoPdvs = false
	somenteAbertos = true
	cancelToken: CancelTokenSource | null = null
	pdvComvalor: PdvsComValores[] = []
	valorLimiteCaixa: number | null = 0
	valorCheckbox: boolean | null = null
	idTurno: string | null = null
	valorProgresso = 0
	interval: any
	mostra = false
	ocultarValores = false

	@Inject('LojaServiceAdapter')
	lojaService!: LojaServiceAdapter

	@Inject('LojaServiceAdapter')
	turnoService!: LojaServiceAdapter

	ocultaValores(){
		this.ocultarValores = !this.ocultarValores
	}

	created() {
		clearInterval(this.interval)
		this.interval = setInterval(() => {
			this.valorProgresso += Math.random() * (15 - 5) + 5

			if (this.valorProgresso < 100) return

			this.buscar()
		}, 60000)
	}

	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}


	get valorCaixa() {
		return this.pontosDeVenda?.valorEmCaixa
	}

	validarSubTotalDaVenda(venda: Venda) {
		let subTotalDaVenda = obterTotalDosItensDaVenda(venda)
		if (this.venda.tipoDeTransacao === 'Devolução') {
			return subTotalDaVenda
		} else if (subTotalDaVenda < 0) {
			return (subTotalDaVenda = 0)
		}
		return subTotalDaVenda
	}


	async buscar() {


		if (this.cancelToken) this.cancelToken.cancel()
		if (!this.idLoja) return

		try {
			this.cancelToken = Axios.CancelToken.source()
			this.mostra = true


			const valoresPdv = await this.turnoService.getPdvsDaLojaComValores(
				this.idLoja,
				this.somenteAbertos,
				{
					cancelToken: this.cancelToken.token,
					params: {
						size: 60,
					},
				},
			)

			const valorLimite = await this.lojaService.get(this.idLoja)
			this.valorLimiteCaixa = valorLimite.configuracaoDaLoja.valorLimiteDeCaixa
			this.pdvComvalor = valoresPdv.content
		} catch (error: any) {
			AlertModule.setError("Falha ao buscar PDV")
		} finally {
			this.mostra = false
			this.buscandoPdvs = false
		}
		this.valorProgresso = 0
	}

	get idLoja() {
		return this.$route.query.l as string
	}

	validarPdvHabilitado(pontosDeVenda: PontoDeVenda) {
		return pontosDeVenda.aberto || !pontosDeVenda.ativo
	}

	get pdvsOrdenados() {
		return [...this.pdvComvalor].sort((pdvA, pdvB) =>
			pdvA.nome.localeCompare(pdvB.nome),
		)
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get valorTotal() {
		return obterTotalDaVenda(this.venda)
	}


	retornaCorDoCard(pdv) {
		if (!this.valorLimiteCaixa) return 'green'

		return pdv.valorEmCaixa >= this.valorLimiteCaixa && pdv.aberto
			? 'red'
			: 'green'
	}

	@Watch('idLoja', { immediate: true })
	async onChangeIdLoja() {
		this.pdvComvalor = []
		this.buscandoPdvs = true
		this.buscar()
	}

	criarSangria(nome: string, valorEmCaixa: number, turnoDeVendaId: string) {
		this.dialogoDeSangria.mostrar(nome, valorEmCaixa, turnoDeVendaId)
	}

	mostraValoresPagamentos(idTurno: string) {
		this.dialogoValorPagamentos.mostrar(idTurno)
	}
}
