




















import { Loja, PdvsComValores, TurnoDetalhado, Usuario } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import type { LojaServiceAdapter } from '@/usecases'
import Axios, { CancelTokenSource } from 'axios'
import { Inject } from 'inversify-props'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

@Component
export default class DialogoValorPagamentos extends Vue {
	mostra: boolean | null = null
	loja: Loja | null = null
	cancelToken: CancelTokenSource | null = null
	valorPagamento: TurnoDetalhado[] = []
	pdvComValores: PdvsComValores | null = null
	Turnoid: string | null = null
	usuario: Usuario | null = null

	@Inject('LojaServiceAdapter')
	lojaService!: LojaServiceAdapter

	async buscaValoresPagamento() {
		if (this.cancelToken) this.cancelToken.cancel()
		if (!this.Turnoid) return
		try {
			this.cancelToken = Axios.CancelToken.source()
			const valoresPagamentos = await this.lojaService.getTurnoDetalhado(
				this.Turnoid,
				{
					cancelToken: this.cancelToken.token,
					params: {
						size: 60,
					},
				},
			)

			this.valorPagamento = valoresPagamentos.content
		} catch (error: any) {
			this.mostra = false
			this.valorPagamento = []
			AlertModule.setError("Erro ao buscar valores de pagamento")
		}
	}

	@Watch('idLoja', { immediate: true })
	async onChangeLoja() {
		this.buscaValoresPagamento()
	}

	mostrar(idTurno: string) {
		this.Turnoid = idTurno
		this.buscaValoresPagamento()
		this.mostra = true
	}

	get pdvDetalhados() {
		const forma = 'Dinheiro'
		return [...this.valorPagamento]
			.filter(pdv => pdv.formaDePagamento === forma)
			.sort((pdvA, pdvB) => pdvA.formaDePagamento.localeCompare(pdvB.formaDePagamento));
	}
}
